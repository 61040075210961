a,
button,
h1,
h2,
h3,
h4,
h5,
h6,
input,
ol,
p,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font: inherit;
  vertical-align: baseline;
  background: transparent;
  list-style: none;
  overflow: visible; }

button::-moz-focus-inner,
input::-moz-focus-inner,
textarea::-moz-focus-inner {
  margin: 0;
  padding: 0;
  border: 0; }

.leaflet-control.leaflet-searchbox-control-wrapper {
  pointer-events: none;
  margin-left: 0;
  margin-top: 0; }

.leaflet-searchbox-control {
  pointer-events: auto;
  position: absolute;
  left: 0px;
  margin: 16px;
  top: 0px;
  transition: left 0.5s;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  transition-property: -webkit-transform, transform, visibility, opacity;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1); }
  .leaflet-searchbox-control-container {
    position: relative;
    background: #fff;
    border-radius: 2px;
    box-sizing: border-box;
    width: 360px;
    height: 48px;
    border-bottom: 1px solid transparent;
    padding: 12px 70px 11px 70px;
    line-height: 24px;
    transition-property: background, box-shadow;
    transition-duration: 0.3s; }
  .leaflet-searchbox-control-shadow {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02); }
  .leaflet-searchbox-control-menu-container {
    position: absolute;
    z-index: 1003;
    left: 0;
    top: 0; }
  .leaflet-searchbox-control-menu-button {
    display: block;
    cursor: pointer;
    padding: 12px 16px; }
    .leaflet-searchbox-control-menu-button:before {
      content: "";
      display: block;
      background-image: url(images/sprite.@2x.png);
      background-position: 0px 0px;
      background-size: 96px 72px;
      height: 24px;
      width: 24px;
      opacity: 0.62; }
  .leaflet-searchbox-control-input {
    position: relative;
    width: 100%; }
  .leaflet-searchbox-control-search-container {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    border-left: 1px solid #ddd;
    height: 28px; }
  .leaflet-searchbox-control-search-button {
    display: block;
    padding: 2px 15px;
    cursor: pointer;
    opacity: 0.61; }
    .leaflet-searchbox-control-search-button:hover {
      opacity: 0.98; }
    .leaflet-searchbox-control-search-button:before {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      background-image: url(images/sprite.@2x.png);
      background-position: -24px 0px;
      background-size: 96px 72px;
      opacity: 0.5; }
  .leaflet-searchbox-control-search-result {
    width: 100%;
    background: #fff;
    position: absolute;
    left: 0;
    top: 47px;
    border-top: 1px solid #ccc;
    z-index: -1;
    display: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02); }

.leaflet-searchbox-home {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: bottom;
  background-image: url(images/sprite.@2x.png);
  background-position: 0px -24px;
  background-size: 96px 72px; }

.leaflet-searchbox-result-list-item {
  padding: 5px 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }
  .leaflet-searchbox-result-list-item:hover {
    background: #f9f9f9; }
  .leaflet-searchbox-result-list-item a {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }

.leaflet-searchbox-panel {
  pointer-events: auto;
  width: 300px;
  float: left;
  height: 550px;
  background: white;
  position: relative;
  transition-property: left;
  transition-duration: 0.5s;
  left: -100%; }
  .leaflet-searchbox-panel-header {
    display: table; }
    .leaflet-searchbox-panel-header-container {
      display: table-row; }
    .leaflet-searchbox-panel-header-title {
      display: table-cell;
      font-weight: 600;
      font-size: 20px;
      text-align: center;
      width: 95%;
      line-height: 24px;
      padding-top: 10px;
      padding-bottom: 10px;
      color: #c3c3c3; }
  .leaflet-searchbox-panel-close-button {
    background-image: url(images/sprite.@2x.png);
    background-position: -24px -24px;
    background-size: 96px 72px;
    height: 24px;
    width: 24px;
    margin: 0 5px;
    cursor: pointer;
    opacity: 0.6;
    vertical-align: bottom; }
    .leaflet-searchbox-panel-close-button:hover {
      opacity: 0.94; }
  .leaflet-searchbox-panel-list {
    border-top: 1px solid #d9d9d9;
    color: #777;
    font-size: 13px;
    padding-top: 10px; }
    .leaflet-searchbox-panel-list-item {
      padding: 10px 20px 20px 20px;
      cursor: pointer; }
      .leaflet-searchbox-panel-list-item .button,
      .leaflet-searchbox-panel-list-item a {
        vertical-align: middle; }
      .leaflet-searchbox-panel-list-item-icon {
        width: 22px;
        height: 22px;
        display: inline-block;
        opacity: 0.5;
        vertical-align: middle;
        margin-right: 25px; }
  .leaflet-searchbox-panel .icon-folder {
    background-image: url(images/sprite.@2x.png);
    background-position: -48px 0px;
    background-size: 96px 72px; }
  .leaflet-searchbox-panel .icon-cloudy {
    background-image: url(images/sprite.@2x.png);
    background-position: -48px -24px;
    background-size: 96px 72px; }
  .leaflet-searchbox-panel .icon-potrait {
    background-image: url(images/sprite.@2x.png);
    background-position: 0px -48px;
    background-size: 96px 72px; }
  .leaflet-searchbox-panel .icon-local-dining {
    background-image: url(images/sprite.@2x.png);
    background-position: -24px -48px;
    background-size: 96px 72px; }
  .leaflet-searchbox-panel .icon-bike {
    background-image: url(images/sprite.@2x.png);
    background-position: -48px -48px;
    background-size: 96px 72px; }
  .leaflet-searchbox-panel .icon-local-carwash {
    background-image: url(images/sprite.@2x.png);
    background-position: -72px 0px;
    background-size: 96px 72px; }
